import React from "react";
import DrupalImage from "./DrupalImage";


export default ({ data }) => {
    const {
        field_title_component_slider_it = [],
        field_text_comp_slider_it = [],
        field_link_component_slider_it = [],
        field_img_component_slider_item = [],
    } = data || {};

    const [title] = field_title_component_slider_it;
    const [text] = field_text_comp_slider_it;
    const [link] = field_link_component_slider_it;

    const [ imgData ] = field_img_component_slider_item;
    return (
        <div className="row">
            <div className="col-md-12 banner-img">
                {imgData !== undefined &&
                    <DrupalImage { ...imgData } />
                }
            </div>
            <div className="col-md-3 banner-text">
                {title !== undefined &&
                    <h4>{title.value}</h4>
                }
                {text !== undefined &&
                    <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                }
                {link !== undefined &&
                    <a href={link.uri}>{link.title}</a>
                }
            </div>
        </div>
    )
}

