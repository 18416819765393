import React from "react";
import Slider from 'react-slick';

import BannerItem from "./BannerItem";
export default ({ data }) => {
    const settings = {
        autoplay: true,
        arrows : false,
        autoplaySpeed: 6000,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const {
        field_items_component_slider = [],
    } = data || {};

    const items = field_items_component_slider.map((data, i) => <BannerItem key={i} data={data} />);
    return (
        <section className="mein-banner">
            <div className="container">
                <Slider {...settings}>
                    {items}
                </Slider>
            </div>
        </section>
    );
}